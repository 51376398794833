<template>
  <div class="position">
    <el-cascader
      size="medium"
      v-model="_value"
      :options="positions"
      :props="defaultProps"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  name: "Position",
  data() {
    return {
      positions: null,
      defaultProps: {
        label: "content",
        children:
          this.$attrs.type == "grade" ? "sysJobGradeList" : "sysPostList",
        multiple: true,
        value: 'id',
        emitPath: false,
        checkStrictly:!!(this.$attrs.checkStrictly)
      },
    };
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    let func =
      this.$attrs.type == "grade"
        ? this.$api.personnel.sysJobGradeList
        : this.$api.personnel.sysPostList;
    func().then((res) => {
      this.positions = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.position {
}
</style>