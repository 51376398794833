<template>
  <el-date-picker
    v-model="_value"
    placeholder="选择日期"
    value-format="yyyy-MM-dd"
    v-bind="$attrs"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    size="medium"
    :picker-options="pickerOptions"
  >
  </el-date-picker>
</template>

<script>
export default {
  name: "Date",
  data() {
    const { limit } = this.$attrs;
    return {
      pickerOptions: {
        disabledDate(time) {
          if (limit == "future") {
            return time.getTime() < Date.now();
          } else {
            return false;
          }
        },
      },
    };
  },
  props: {
    value: {
      type: String | Array,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>